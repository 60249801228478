import { orderBy } from 'lodash'

const data = {
  rules: [
    { key: 'USER_ROLE_DOCTOR', value: 1, text: 'Médico laudante' },
    { key: 'USER_ROLE_PATIENT', value: 2, text: 'Paciente' },
    { key: 'USER_ROLE_ADMIN', value: 3, text: 'Administrador' },
    { key: 'USER_ROLE_DOCTOR_REQUESTOR', value: 4, text: 'Médico solicitante' },
    { key: 'USER_ROLE_COLLABORATOR', value: 5, text: 'Colaborador' },
    { key: 'USER_ROLE_PARTNER_PLACE', value: 6, text: 'Local de atendimento' },
  ],
}

export default {
  rules: orderBy(data.rules, [item => item.text]),
}
