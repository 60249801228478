import { orderBy } from 'lodash'

const data = {
  ufs: [
    { key: 'RO', value: 'RO', text: 'RO' },
    { key: 'AC', value: 'AC', text: 'AC' },
    { key: 'AM', value: 'AM', text: 'AM' },
    { key: 'RR', value: 'RR', text: 'RR' },
    { key: 'PA', value: 'PA', text: 'PA' },
    { key: 'AP', value: 'AP', text: 'AP' },
    { key: 'TO', value: 'TO', text: 'TO' },
    { key: 'MA', value: 'MA', text: 'MA' },
    { key: 'PI', value: 'PI', text: 'PI' },
    { key: 'CE', value: 'CE', text: 'CE' },
    { key: 'RN', value: 'RN', text: 'RN' },
    { key: 'PB', value: 'PB', text: 'PB' },
    { key: 'PE', value: 'PE', text: 'PE' },
    { key: 'AL', value: 'AL', text: 'AL' },
    { key: 'SE', value: 'SE', text: 'SE' },
    { key: 'BA', value: 'BA', text: 'BA' },
    { key: 'MG', value: 'MG', text: 'MG' },
    { key: 'ES', value: 'ES', text: 'ES' },
    { key: 'RJ', value: 'RJ', text: 'RJ' },
    { key: 'SP', value: 'SP', text: 'SP' },
    { key: 'PR', value: 'PR', text: 'PR' },
    { key: 'SC', value: 'SC', text: 'SC' },
    { key: 'RS', value: 'RS', text: 'RS' },
    { key: 'MS', value: 'MS', text: 'MS' },
    { key: 'MT', value: 'MT', text: 'MT' },
    { key: 'GO', value: 'GO', text: 'GO' },
    { key: 'DF', value: 'DF', text: 'DF' },
  ],
}

export default {
  ufs: orderBy(data.ufs, [item => item.text]),
}
