<template>
  <div>
    <TempPasswordDialog ref="tempPasswordDialog" />
    <div v-if="loading" class="loading-container pt-12">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div>
    <v-form
      ref="form"
      @submit.prevent="save"
      v-show="!loading"
      :disabled="$attrs.disabled"
    >
      <ErrorMessages :messages="errors"></ErrorMessages>
      <v-text-field
        v-model="form.name"
        :rules="$rules.required"
        label="Nome"
        required
        dense
        outlined
      ></v-text-field>

      <v-text-field
        v-model="form.username"
        :rules="$rules.required"
        label="Login"
        required
        dense
        outlined
      ></v-text-field>

      <v-switch
        v-model="form.active"
        label="Ativo"
        color="primary"
        class="mt-0 mb-2"
      ></v-switch>

      <v-select
        label="Tipo"
        :items="userRoles"
        v-model="form.role"
        :rules="$rules.required"
        dense
        outlined
      ></v-select>

      <v-text-field
        v-model="form.email"
        :rules="$rules.email()"
        label="E-mail"
        placeholder="Digite seu e-mail de usuário"
        required
        dense
        outlined
      ></v-text-field>

      <v-row>
        <v-col class="pb-0">
          <v-text-field
            v-model="form.user_naja_code"
            label="Código usuário"
            v-mask="'############'"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model="form.naja_code"
            label="Código médico"
            v-mask="'############'"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3" class="pt-0">
          <v-select
            label="UF CRM"
            :items="ufs"
            v-model="form.crm_uf"
            required
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            v-model="form.crm_number"
            v-mask="'############'"
            label="Número CRM"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <nj-multiple-select-input
        ref="multipleSelectInput"
        label="Perfis de acesso"
        max-width="100%"
        v-model="form.roles"
        :objects="roles"
        :hide-details="false"
      />

      <slot></slot>
    </v-form>
  </div>
</template>
<style scoped>
.v-form {
  max-width: 600px;
}
</style>
<script>
import Api from '@/resources/NajaSocialApi'
import USER_ORGANIZATION_ENUM from '@/enumerations/user_organization_enum'
import UFS_ENUM from '@/enumerations/ufs_enum'
import TempPasswordDialog from './temp_password_dialog'
import ErrorMessages from '@/components/ErrorMessages'

export default {
  name: 'UsersForm',
  components: { TempPasswordDialog, ErrorMessages },
  data: () => ({
    userRoles: USER_ORGANIZATION_ENUM.rules.filter(x =>
      ['USER_ROLE_COLLABORATOR', 'USER_ROLE_DOCTOR'].includes(x.key)
    ),
    ufs: UFS_ENUM.ufs,
    saveLoading: false,
    loading: false,
    roles: [],
    errors: [],
    form: {},
  }),

  mounted() {
    this.init()
  },

  methods: {
    async init() {
      try {
        this.loading = true
        const rolesResponse = await Api.roles.search()
        this.roles = rolesResponse.data.roles.map(role => {
          return { value: role.id, text: role.name }
        })

        if (this.$route.params.id) {
          await this.fetch(this.$route.params.id)
        }
      } catch (e) {
        this.$root.$children[0].toast('Erro ao buscar dados')
        throw e
      } finally {
        this.loading = false
      }
    },

    _parseForm(data) {
      if (data.crm != null && data.crm != '') {
        data.crm_uf = data.crm.substring(0, 2)
        data.crm_number = data.crm.substring(2)
      }

      this.form = { ...this.form, ...data }
    },

    fetch(id) {
      // this.loading = true
      return Api.userOrgaizations
        .fetch(id)
        .then(async response => {
          this._parseForm(response.data.user_organization)
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast('Erro ao buscar usuário')

            this.loading = false
          } else {
            throw error
          }
        })
        .finally(() => {
          this.$scrollTop()
          // this.loading = false
        })
    },

    save() {
      this.saveLoading = true
      const crm =
        this.form.crm_uf != null && this.form.crm_number != null
          ? `${this.form.crm_uf}${this.form.crm_number}`
          : null
      const data = {
        user_organization: {
          email: this.form.email,
          naja_code: this.form.naja_code,
          role: this.form.role,
          roles: this.form.roles,
          user_naja_code: this.form.user_naja_code,
          crm: crm,
          user_attributes: {
            id: this.form.user_id,
            name: this.form.name,
            username: this.form.username,
            active: this.form.active,
          },
        },
      }

      // when a new user
      const isNew = this.$route.params.id == null
      const tempPassword = Math.random().toString(36).substr(2, 8)
      if (isNew) {
        data.user_organization.user_attributes.password = tempPassword
        data.user_organization.user_attributes.password_confirmation =
          tempPassword
      }

      Api.userOrgaizations
        .save(this.$route.params.id, data)
        .then(response => {
          this._parseForm(response.data.user_organization)
          this.$root.$children[0].toast('Usuário salvo com sucesso')

          if (isNew) {
            this.$refs.tempPasswordDialog.open(tempPassword, this.form.id)
          } else {
            this.$router.push({
              name: 'UserShow',
              params: { id: this.form.id },
            })
          }
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast('Erro ao salvar usuário')
            if (error.response.status != 500) {
              this.errors = error.response.data.errors

              this.$refs.form.validate()
            }
          } else {
            throw error
          }

          // throw error;
        })
        .finally(() => {
          this.$scrollTop()
          this.saveLoading = false
        })
    },
  },
}
</script>
