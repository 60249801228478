<template>
  <v-dialog v-model="active" max-width="400" class="temp-password-dialog">
    <template v-slot:default>
      <v-card>
        <v-toolbar color="primary" elevation="0" dark>
          <v-icon class="mr-2"> mdi-lock </v-icon>
          <v-toolbar-title>Senha inicial</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="active = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-row>
            <v-col>
              <p>
                No primeiro login o usuário terá opção para alterar sua senha.
              </p>
              <v-text-field
                v-model="password"
                label="Senha Inicial"
                readonly
                dense
                outlined
                @click:append="copyText"
                append-icon="mdi-content-copy"
                :hint="copied ? 'Copiado!' : ''"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            elevation="0"
            color="primary"
            :to="{ name: 'UserShow', params: { id: userId } }"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
export default {
  name: 'TempPasswordDialog',
  data: () => ({
    active: false,
    password: null,
    copied: false,
  }),
  methods: {
    open(password, userId) {
      this.password = password
      this.userId = userId
      this.active = true
    },

    copyText() {
      navigator.clipboard.writeText(this.password)
      this.copied = true

      setTimeout(() => {
        this.copied = false
      }, 2000)
    },
  },
}
</script>
<style scoped>
.temp-password-dialog >>> .v-card__text {
  min-height: 400px;
}
</style>
